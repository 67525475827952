import renderHTML from 'react-render-html';

function JobContent(props) {
    return(
        <div class={props.visible ? 'content_visible' : 'content_invisible'}>
            {renderHTML(props.content)}
        </div>
    )
}

export default JobContent;
