import React, {useRef} from 'react'

function JobTitle(props) {

    function handleClick(e) {
        props.titleHandleClick(e);
    }

    const cursor = useRef(null);

    return (
        <div id={props.title} class="section" onClick={handleClick}>
            <h2>{props.title} ~ %&nbsp;
                {props.visible ? 
                <span class="open">click here to close this section</span>
                :
                <span class="closed">click here to read about {props.title}</span>
                }
                &nbsp;<span class="cursor" ref={cursor}>&nbsp;</span>
            </h2>
        </div>
    )
}

export default JobTitle;
