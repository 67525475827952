import laurent from './img/laurent.png'

function Header(props) {
    return (
        <div class="maintitle">
            <div>
            <img src={laurent} alt="Laurent's face" width="100px" height="100px"/>
            </div>
        <div class="title">
            <h1>Laurent Ach</h1>
            <h2>Tech and team leader, expert in AI and UX</h2>
        </div>
        <div class="links">
        <ul>
            <li><a href="https://ach3d.medium.com" rel="noreferrer" target="_blank">some articles I wrote</a></li>
            <li><a href="https://twitter.com/ach3d" rel="noreferrer" target="_blank">@ach3</a></li>
            <li><a href="https://qwant.com" rel="noreferrer" target="_blank">Qwant</a></li>
        </ul>
        </div>
            
        </div>
)
}

export default Header;
