import React, { useState } from "react";
import JobTitle from './JobTitle.js';
import JobContent from './JobContent.js'

function Job(props) {
    const [visible, setVisible] = useState(false);
    let title = props.title;
    let content = props.content;
    function titleHandleClick(e) {
        e.preventDefault();
        setVisible(!visible)
    }

    return (
        <div>
        <JobTitle title={title} visible={visible} titleHandleClick={titleHandleClick}/>
        <JobContent content={content} visible={visible}/>
        </div>
    )
}

export default Job;
