import './App.css';
import Job from './Job.js';
import Header from './Header.js';

function App() {
  return (
    <div class="main_div">
    <Header/>
    {job_title.map(job => {
        return <Job title={job.title} content={job.content}/>
    })}
    </div>
  );
}

const job_title = [
  {title:'Qwant', content:`
<p>I am the CTO of <a href="https://www.qwant.com/" target="_blank">Qwant</a>, a great search engine respecting data privacy and built by an excellent tech team in France.
`
  },

  {title:'Rakuten Institute of Technology', content:`
<p>I managed the French team of <a href="https://rit.rakuten.com/" target="_blank">Rakuten Institute of Technology</a> (RIT) from 2014 to 2021,
the research and innovation department of Rakuten group. RIT teams are in Tokyo, Singapore, Paris, Boston, San Mateo.
I <a href="https://global.rakuten.com/corp/news/press/2014/0218_02.html">created</a> RIT Paris in 2014,
located in <a href="https://www.rakuten.fr/">Rakuten France</a> office,
working on innovative projects using machine learning, especially deep learning for Rakuten Group businesses.
For a while, I was also CTO of Rakuten France.
`
  },
  {title:'Living Actor', content:`
<p>I was CTO of Living Actor from 2006 to 2013.
We developed software for avatar creation and animation and we built on-line applications and web services used by many small and
big companies in Europe and the United States.
The Living Actor&trade; technology can be seen at <a href="https://www.livingactor.com">livingactor.com</a>.
</p>
</div>


<h3>Research Collaborative Projects</h3>
<p>I participated in several French and European research projects.</p><ul>
<li><p>I led <b>Avatar 1:1</b> (Avatar at Human Scale), a French collaborative project,
which gathers three companies (Living Actor, Cliris, Mazedia) and two research organizations (Institut Telecom - CNRS, Université Pierre et Marie Curie).
Its goal is to create a dialog with an avatar in a public area, including verbal and non verbal communication dimensions.</p>
</li>
<p>ILHAIRE was a European project about laughter, gathering
University of Mons (Belgium), CNRS (France), University of Augsburg, Università degli Studi of Genova,
University College London, Queen's University Belfast, University of Zurich, Supélec (France), and Living Actor.</p>
<div class="clear"></div>
</li>
<li>
<p>I led MyPresentingAvatar project, finished in 2012,
which allowed to automatically generate presentations by an avatar.
The speech and gestures were generated from text semantic analysis.
</p>
</li></ul>`
  },
  {title:'Safran Group', content:`
<p>Around 2004, I organised processes and tools for SAGEM (now SAFRAN) to manage the compliance of mobile phones with a huge
database of requirements coming from many operators in the world.
`
  },
  {title:'Visiospace', content:`
<p>During the last years of the Internet bubble I took part in a great project of 3D streaming data that allowed
to navigate inside CAD models and virtual cities without any limitations of texture resolution or numbers of polygons.</p>
`
  },
  {title:'Thales Group', content:`
<p>In the 90's I was an Artificial Intelligence and 3D engineer at Syseca, which later became part of Thales. In this company,
I worked on a project for the French Navy to create a track fusion system using neural networks. I also created the 3D
visualization of SERPAM, an Air Force mission debriefing system that is still in use and evolving after more than ten years.</p>

<p>Around 1995, I joined a team of 3D pionneers coming to Thales from
<a href="https://histoire3d.siggraph.org/index.php?title=TDI">TDI</a> to form the
Virtual Reality group whose customers included the Industrial Design Department of Renault in France and Honda R&amp;D in Japan.
I also participated in the &quot;VR for Europe&quot; project funded by the European commission to promote the
use of Virtual Reality in the European industry.</p>
`
  },
];

export default App;
